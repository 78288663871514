import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Masonry from "react-masonry-css";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./Gallery.css";
import { useHistory } from "react-router-dom";
import { useCallback } from "react";

const Gallery = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const galleryId = searchParams.get("id");

  const [images, setImages] = useState([]);
  const [lightboxImages, setLightboxImages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const history = useHistory();

  const redirectToHome = useCallback(() => {
    history.push("/");
}, [history]);

  const breakpointColumnsObj = {
    default: 4, // 4 columns on large screens
    1100: 3, // 3 columns on medium screens
    700: 2, // 2 columns on mobile screens
    500: 2, // 1 column on very small screens
  };

  useEffect(() => {
    if (!galleryId) {
      setError("No gallery ID provided.");
      setLoading(false);
      return;
    }

    const fetchGallery = async () => {
      try {
        const response = await axios.get(
        //   `http://localhost:4000/api/gallery/${galleryId}`
          `https://dreamsjewels-stock.onrender.com/api/gallery/${galleryId}`
        );
        setImages(response.data.data.images);

        // Convert images for ImageGallery format
        const formattedImages = response.data.data.images.map((img) => ({
          original: img.url,
          thumbnail: img.url,
          description: img.code, // Add code in the lightbox
        }));
        setLightboxImages(formattedImages);
      } catch (err) {
        setError("Failed to load images.");
        redirectToHome(); // Redirect on error
    } finally {
        setLoading(false);
      }
    };

    fetchGallery();
  }, [galleryId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="gallery-container">
      <br/>
      <h1 className="mainheading-text">PICKED FOR YOU</h1>
      <br/>

      {/* Masonry Grid */}
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="gallery-masonry"
        columnClassName="gallery-column"
      >
        {images.map((img, index) => (
          <div key={index} className="gallery-item">
            <img
              src={img.url}
              alt={`Gallery ${index}`}
              className="gallery-image"
              onClick={() => {
                setSelectedIndex(index);
                setIsOpen(true);
              }}
            />
            <p className="image-code">{img.code}</p> {/* Display code below image */}
          </div>
        ))}
      </Masonry>

      {/* Lightbox with Thumbnail Slider */}
      {isOpen && (
        <div className="lightbox-overlay" onClick={() => setIsOpen(false)}>
          <div className="lightbox-container" onClick={(e) => e.stopPropagation()}>
            <ImageGallery
              items={lightboxImages}
              startIndex={selectedIndex}
              showThumbnails={true}
              showFullscreenButton={true}
              showPlayButton={false}
              showNav={true}
              onSlide={(index) => setSelectedIndex(index)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
